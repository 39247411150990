let resource = {
  portfolio: [
    // {
    //     title: "어딤",
    //     subtitle: "위치공유 메신저",
    //     image: "/images/icon_udim.png",
    //     activeColor: "",
    //     link: "",
    //     description:
    //         "채팅을 하면서 서로의 위치를 공유할수있습니다. 약속을 했을때,여행을 갔을때 등등 상대의 위치를 공유하며 원활한 의사소통을 할수 있습니다. 어딤에서는 상대방이 지금 어디인지 더이상 물어볼 필요가 없습니다.",
    // },
    {
      name: "swipeball",
      title: "블럭깨기",
      subtitle: "멘토찾기 / 멘토되기",
      image: "/images/icon_swipeball.png",
      activeColor: "",
      linka:
        "https://play.google.com/store/apps/details?id=com.swdev.swipeball",
      linki: "",
      description:
        "간단한 조작으로 블럭을 격파해 높은 레벨로 올라가는 간단한 게임.",
    },
    {
      name: "clayshooting",
      title: "Clay Shooting",
      subtitle: "Clay Shooting",
      image: "/images/icon_clayshooting.png",
      activeColor: "",
      linka:
        "https://play.google.com/store/apps/details?id=com.swdev.clayshooting",
      linki:
        "https://apps.apple.com/us/app/clay-shooting-master-of-speed/id1332711640",
      description: "슈팅게임",
    },
    {
      name: "mentoring",
      title: "멘토링",
      subtitle: "멘토찾기 / 멘토되기",
      image: "/images/icon_mentoring.png",
      activeColor: "",
      linka: "https://play.google.com/store/apps/details?id=com.sn40.mentoring",
      linki:
        "https://apps.apple.com/us/app/%EB%A9%98%ED%86%A0%EB%A7%81/id1479442984",
      description:
        "나의 멘토를 찾아보자.고민, 진로, 인생상담등을 공유하고 대답해줄 멘토를 찾을수 있습니다. 나도 멘토가 되보자. 누군가의 고민을 잘 들어주는 것만으로도 당신은 훌륭한 멘토가 될수 있습니다. 거기에 더해 진심으로 대답해줄수 있다면 당신도 이제는 누군가의 멘토!!",
    },
    {
      name: "timer",
      title: "Lite Timer",
      subtitle: "위치공유 메신저",
      image: "/images/icon_timer.png",
      activeColor: "",
      linka: "https://play.google.com/store/apps/details?id=com.sn40.litetimer",
      linki: "https://apps.apple.com/us/app/lite-timer-lite/id1481691239",
      description:
        "Super Lite Super Simple Super Easy Beautiful Timer  fitness timer cook timer useful timer",
    },
    {
      name: "camping",
      title: "더 캠핑",
      subtitle: "대한민국 캠핑장",
      image: "/images/icon_thecamping.png",
      activeColor: "",
      linka:
        "https://play.google.com/store/apps/details?id=com.indieidea.thecamping",
      linki:
        "https://apps.apple.com/us/app/%EB%8D%94-%EC%BA%A0%ED%95%91/id1487456790",
      description: "대한민국 캠핑장정보, 현황, 편의시설 등의 정보검색",
    },
    {
      name: "unse",
      title: "오늘의 운세",
      subtitle: "육효운세",
      image: "/images/icon_unse.jpg",
      activeColor: "",
      linka:
        "https://play.google.com/store/apps/details?id=com.indidea.fortune",
      linki:
        "https://apps.apple.com/us/app/%EC%98%A4%EB%8A%98%EC%9D%98-%EC%9A%B4%EC%84%B8-%EC%9C%A1%ED%9A%A8%EC%9A%B4%EC%84%B8/id1532638149",
      description: "오늘의운세, 육효운세, 운세, 2021 신년운세",
    },
    {
      name: "haim",
      title: "하임",
      subtitle: "하임 연애 커뮤니티 친구만들기",
      image: "/images/icon_halftime.png",
      activeColor: "",
      linka: "https://play.google.com/store/apps/details?id=com.indidea.half50",
      linki: "https://apps.apple.com/us/app/haim/id1536269272",
      description: "연애의 라이프사이클에대해 이야기하는 공간",
    },
    {
      name: "gollazo",
      title: "GollaZo",
      subtitle: "하나만 골라줘",
      image: "/images/icon_gollazo.png",
      activeColor: "",
      linka:
        "https://play.google.com/store/apps/details?id=com.indidea.gollazo",
      linki: "https://apps.apple.com/us/app/gollazo/id1603757240",
      description:
        "딜레마 결정력 향상프로젝트. 간단하게 등록하고 선택의 시간을 줄여보세요.",
    },
    {
      name: "routeam",
      title: "RouTeam",
      subtitle: "팀과 함께 만드는 성장의 여정",
      image: "/images/icon_routeam.png",
      activeColor: "#9d4edd",
      linka:
        "https://play.google.com/store/apps/details?id=com.indieidea.routeam",
      linki: "https://apps.apple.com/kr/app/routeam/id6483754548",
      description:
        "Routeam은 스타트업과 팀을 위한 실시간 협업 도구로, 화이트보드, 칸반, 일정 관리, 메모 작성 등 다양한 기능을 통해 효율적인 팀워크와 창의적인 작업을 지원합니다. 또한, 커뮤니티 기능을 통해 팀원들과 아이디어를 공유하고 피드백을 받으며 성장할 수 있는 네트워킹 기회를 제공합니다. 비대면 회의와 협업, 커뮤니티 활동에 최적화된 플랫폼입니다.",
    },
  ],
};
export default resource;
