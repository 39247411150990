import React, { useEffect } from "react";
// import "bulma/css/bulma.min.css";

import resource from "../resource";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
// import "../App.css";

const Portfolio = () => {
  const { search } = useLocation();
  const query = queryString.parse(search);
  const { id, appname } = query;
  const data = appname
    ? resource.portfolio.find((d) => d.name === appname)
    : null;
  console.log(data);
  if (data === null) {
    window.location.href = "/portfolio";
  }
  useEffect(() => {
    const APPLEAPPID = "1603757240";
    const PACKAGENAME = "com.indidea.gollazo";
    const APP_STORE_LINK = `itms-apps://apps.apple.com/app/id${APPLEAPPID}?action=write-review`;
    const PLAY_STORE_LINK = `market://details?id=${PACKAGENAME}`;
    function ios_go_url(param) {
      var url = "gollazoapp://readid=" + param;
      console.log(url);
      setTimeout(function () {
        window.location.href = APP_STORE_LINK;
      }, 1000);
      window.location.href = url;
    }
    function aos_go_url(param) {
      var url = "gollazoapp://readid=" + param;
      console.log(url);
      setTimeout(function () {
        window.location.href = PLAY_STORE_LINK;
      }, 1000);
      window.location.href = url;
    }
    function mo_chk() {
      var os;
      var mobile = /iphone|ipad|ipod|android/i.test(
        navigator.userAgent.toLowerCase()
      );
      if (mobile) {
        var userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.search("android") > -1) {
          os = "android";
        } else if (
          userAgent.search("iphone") > -1 ||
          userAgent.search("ipod") > -1 ||
          userAgent.search("ipad") > -1
        ) {
          os = "ios";
        } else {
          os = "otehr";
        }
      } else {
        os = "pc";
      }
      return os;
    }
    // const _id = getParam("id");
    console.log(id);
    if (data.name === "gollazo" && id !== undefined) {
      const _id = id;
      const _device = mo_chk();
      // console.log(_id, _device);
      if (_device === "ios") {
        ios_go_url(_id);
      } else {
        aos_go_url(_id);
      }
    }
    return () => {
      // cleanup;
      // clearAllValue();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  // console.log(resource.portfolio.find(d => d.name === "gollazo").linka);
  return (
    <main>
      {data !== null && (
        <div className="container portfolio-container container-pd">
          <div className="title appname m-5 text-blue-500 font-bold text-center text-4xl">
            {data.title}
          </div>
          <div className="columns is-multiline">
            <div className="contentWrapper  m0auto">
              <div>
                <QRCode value={data.linka} size={200} />
                <a href={data.linka} target="_blank" rel="noopener noreferrer">
                  ANDROID
                  <br />
                  설치 바로가기
                </a>
              </div>
              <div>
                <QRCode value={data.linki} size={200} />
                <a href={data.linki} target="_blank" rel="noopener noreferrer">
                  IOS
                  <br />
                  설치 바로가기
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Portfolio;
