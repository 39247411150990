import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="w-full text-center border-t border-grey p-4 bg-gray-700">
        <div className="inner">
          <p className="text-white flex justify-between">
            Copyright&copy; Indie IDEA 2019
            <a
              href="https://indidea.notion.site/HAIM-f30c3e9527bd4e558c99ab930fca6afd"
              rel="noreferrer"
              target="_blank"
            >
              <span className="text-white">Terms</span>
            </a>
          </p>
        </div>
      </footer>
    );
  }
}
