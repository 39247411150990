import React, { useState } from "react";
// import { Route, Link } from "react-router-dom";

// import "react-bulma-components/dist/react-bulma-components.min.css";
// import "bulma/css/bulma.min.css";
// import { Button } from 'react-bulma-components'
// import Contents from "../components/Contents";
import resource from "../resource";
import Modal from "react-modal";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { isMobile } from "react-device-detect";

Modal.setAppElement("#root");

const Portfolio = () => {
  const { search } = useLocation();
  const query = queryString.parse(search);

  const [modalIsOpen, setIsOpen] = useState(
    query?.appname !== undefined ? true : false
  );
  const [modalContents, setModalContents] = useState(
    modalIsOpen
      ? resource.portfolio.filter((d) => d.name === query.appname)[0]
      : []
  );
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile ? "90%" : "550px",
      height: isMobile ? "70%" : "430px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="container mx-auto p-5">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
        {resource.portfolio &&
          resource.portfolio.map((item, i) => {
            return (
              <div
                className=" cursor-pointer"
                key={i}
                onClick={() => {
                  console.log(item.title);
                  setModalContents(item);
                  setIsOpen(true);
                }}
              >
                <div className="w-auto">
                  <div
                    className={`card-image`}
                    style={{ backgroundColor: `${item.activeColor}` }}
                  >
                    <img
                      src={`${item.image.replace(".png", "_b.png")}`}
                      alt={`${item.title}`}
                      className="w-full"
                    />
                  </div>
                  <div className="w-full bg-white">
                    <div className="p-2">
                      <div className="float-left p-2">
                        <img
                          src={`${item.image}`}
                          alt={`${item.title}`}
                          className="rounded-full w-16 h-16"
                        />
                      </div>
                      <div className="h-16 w-full py-2">
                        <p className="text-gray-500 text-xl truncate">{`${item.title}`}</p>
                        <p className="text-gray-500 text-sm truncate">{`${item.subtitle}`}</p>
                      </div>
                    </div>
                    <div className="truncate text-gray-500 text-base clear-both p-2">{`${item.description}`}</div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={() => {
            // console.log("s");
          }}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h1 className="modalTitle">{modalContents.title}</h1>
          <button onClick={closeModal} className="button closeBtn">
            x
          </button>
          <div className="contentWrapper flex">
            {modalContents.linka !== "" && (
              <a
                className="text-sm font-bold"
                href={modalContents.linka}
                target="_blank"
                rel="noopener noreferrer"
              >
                <QRCode value={modalContents.linka} size={200} />
                ANDROID
                <br />
                설치 바로가기
              </a>
            )}
            {modalContents.linki !== "" && (
              <a
                href={modalContents.linki}
                target="_blank"
                rel="noopener noreferrer"
              >
                <QRCode value={modalContents.linki} size={200} />
                IOS
                <br />
                설치 바로가기
              </a>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Portfolio;
